* {
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
  scroll-behavior: smooth; }

body {
  width: 100%;
  height: 100%;
  text-align: center;
  overflow-x: hidden;
  font-family: "Montserrat", sans-serif; }

a {
  text-decoration: none;
  cursor: pointer; }

.btn-primary {
  display: block;
  background-color: #e3000e;
  border: none;
  color: #fff;
  font-size: 1em;
  font-weight: bold;
  text-transform: uppercase;
  min-width: 255px;
  padding: 1em;
  margin: 1em auto;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.8);
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease; }
  .btn-primary i {
    margin-left: 1em; }
  .btn-primary:hover {
    background-color: #7e0003;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.8); }

.heading {
  text-align: center;
  font-size: 2em;
  margin: 2em auto;
  color: #e3000e;
  font-style: italic;
  font-weight: bold;
  padding: 0 1em; }
  .heading::after {
    content: "";
    display: block;
    width: 100px;
    height: 3px;
    background-color: #e3000e;
    margin-top: 1em;
    margin-left: 50%;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%); }

.container {
  width: 100%;
  padding: 1em; }

p {
  margin-top: 1em;
  font-size: 1em; }

.main-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100vw;
  padding: 1em;
  background-color: rgba(0, 0, 0, 0.8);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1; }

.main-heading img {
  height: 50px;
  width: auto; }

.main-heading h1 {
  display: none;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease; }
  .main-heading h1 a:hover {
    cursor: pointer;
    color: #e3000e; }

.main-heading h2 {
  display: none; }

.hamburger-icon {
  background: url(/static/media/hamburger-menu.09b9316f.svg) no-repeat center;
  background-size: cover;
  width: 48px;
  height: 40px; }

.dialog-nav {
  position: fixed;
  display: block;
  z-index: 1;
  width: 100vw;
  height: 100vh;
  background: transparent;
  -webkit-animation-duration: 0.3s;
          animation-duration: 0.3s;
  -webkit-animation-timing-function: ease;
          animation-timing-function: ease;
  -webkit-transition: -webkit-transform 0.3s ease;
  transition: -webkit-transform 0.3s ease;
  transition: transform 0.3s ease;
  transition: transform 0.3s ease, -webkit-transform 0.3s ease; }

.open {
  -webkit-transform: translateX(0);
          transform: translateX(0);
  -webkit-animation-name: openDialog;
          animation-name: openDialog; }

.close {
  -webkit-transform: translateX(5000px);
          transform: translateX(5000px);
  -webkit-animation-name: closeDialog;
          animation-name: closeDialog; }

@-webkit-keyframes openDialog {
  0% {
    -webkit-transform: translateX(5000px);
            transform: translateX(5000px); }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0); } }

@keyframes openDialog {
  0% {
    -webkit-transform: translateX(5000px);
            transform: translateX(5000px); }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0); } }

@-webkit-keyframes closeDialog {
  0% {
    -webkit-transform: translateX(0);
            transform: translateX(0); }
  100% {
    -webkit-transform: translateX(5000px);
            transform: translateX(5000px); } }

@keyframes closeDialog {
  0% {
    -webkit-transform: translateX(0);
            transform: translateX(0); }
  100% {
    -webkit-transform: translateX(5000px);
            transform: translateX(5000px); } }

.close-icon {
  cursor: pointer;
  position: fixed;
  width: 2em;
  height: auto;
  top: 0.5em;
  right: 0.5em;
  z-index: 2; }

.hero-image {
  position: relative;
  width: 100vw;
  height: 100vh;
  background: url(/static/media/hero-image.8b86a113.jpg) no-repeat;
  background-position: 0 0;
  background-attachment: fixed;
  background-size: cover;
  -webkit-filter: grayscale(1);
          filter: grayscale(1); }

.hero-text {
  position: absolute;
  top: 55%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  text-align: center;
  color: #fff;
  font-size: 1em;
  background: rgba(0, 0, 0, 0.5);
  padding: 1em;
  border: solid 2px #fff; }
  .hero-text h2 {
    text-transform: uppercase;
    text-shadow: -2px 0 black, 0 2px black, 2px 0 black, 0 -2px black; }

/* RESPONSIVE DESIGN FOR LANDSCAPE MOBILES */
@media screen and (min-width: 480px) and (orientation: landscape) {
  .hero-text {
    min-width: 400px;
    top: 60%; }
  .btn-primary {
    width: 255px; } }

@media screen and (min-width: 640px) and (orientation: landscape) {
  .hero-text {
    min-width: 550px;
    font-size: 1.3em;
    top: 61%; }
  .btn-primary {
    width: 320px; } }

/* RESPONSIVE DESIGN FOR TABLETS */
@media screen and (min-width: 768px) {
  .hero-image {
    height: 50vh; }
  .hero-text {
    top: 25%;
    -webkit-transform: translate(-50%, -25%);
            transform: translate(-50%, -25%);
    font-size: 1.5em; }
  .btn-primary {
    width: 360px; } }

@media screen and (min-width: 1024px) {
  .hero-image {
    height: 60vh; } }

@media screen and (min-width: 1280px) {
  .hamburger-icon {
    display: none; }
  .main-heading {
    text-align: left;
    font-style: italic;
    color: #fff;
    font-size: 1em; }
    .main-heading img {
      display: none; }
    .main-heading h1 {
      display: block;
      margin: 0.5em; }
      .main-heading h1 a {
        color: #fff;
        text-decoration: none; } }

.main-nav {
  position: fixed;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 1; }
  .main-nav a {
    color: #fff;
    text-decoration: none;
    text-transform: uppercase;
    font-size: 1.5em;
    padding: 0.8em;
    position: relative; }
    .main-nav a::before {
      content: "";
      position: absolute;
      width: 0;
      height: 3px;
      background-color: #e3000e;
      -webkit-transition: width 0.3s ease;
      transition: width 0.3s ease;
      left: 50%;
      top: 2em; }
    .main-nav a::after {
      content: "";
      position: absolute;
      width: 0;
      height: 3px;
      background-color: #e3000e;
      -webkit-transition: width 0.3s ease;
      transition: width 0.3s ease;
      right: 50%;
      top: 2em; }
    .main-nav a:hover::before {
      width: 37px; }
    .main-nav a:hover::after {
      width: 37px; }

/* RESPONSIVE DESIGN FOR LANDSCAPE MOBILES */
@media screen and (min-width: 480px) and (orientation: landscape) {
  .main-nav a {
    padding: 0.5em; } }

@media screen and (min-width: 1280px) {
  .dialog-nav {
    height: auto; }
  .main-nav {
    flex-direction: row;
    justify-content: center;
    align-items: center;
    -webkit-transform: translateX(0);
            transform: translateX(0);
    background: transparent;
    height: auto;
    width: auto;
    position: fixed;
    right: 1em;
    top: 1.6em; }
  .close {
    -webkit-transform: translateX(0);
            transform: translateX(0); }
  .close-icon {
    display: none; } }


.services-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-evenly;
  margin: -3em auto 0 auto;
  align-items: baseline; }

.service-card {
  width: 300px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  margin: 1em; }
  .service-card i {
    color: #7e0003;
    font-size: 3em;
    margin: 1em auto 0 auto; }
  .service-card h2 {
    margin: 1em auto; }
    .service-card h2::before {
      content: "";
      display: block;
      width: 100px;
      height: 3px;
      background-color: #7e0003;
      margin: auto auto 1em auto; }

.service-list {
  list-style-type: none;
  text-align: left;
  display: block;
  margin: auto;
  width: 220px; }

@media screen and (min-width: 640px) and (orientation: landscape) {
  .service-card {
    margin: 0; } }

@media screen and (min-width: 1024px) {
  .services-container {
    width: 1024px;
    margin: auto; } }

.about-container {
  display: flex;
  flex-wrap: wrap; }
  .about-container img {
    width: 100%;
    height: auto;
    margin-top: -2em;
    object-fit: contain; }

.call-now {
  width: 250px;
  margin: 1em auto;
  padding: 1em 2em; }
  .call-now i {
    margin-left: 1em; }

/* RESPONSIVE DESIGN FOR LANDSCAPE MOBILES */
@media screen and (min-width: 480px) and (orientation: landscape) {
  .about-container img {
    width: 30%; }
  .about-container .content {
    width: 70%; }
  .call-now {
    width: 325px;
    margin-top: 3em; } }

@media screen and (min-width: 360px) and (orientation: portrait) {
  .call-now {
    width: 315px; } }

@media screen and (min-width: 640px) and (orientation: landscape) {
  .content p:first-of-type {
    margin: 0; }
  .call-now {
    margin-top: 2em;
    width: 530px;
    padding: 2em 3em; } }

@media screen and (min-width: 768px) {
  .about-container {
    flex-wrap: nowrap; }
  .content {
    padding: 1em; }
  .call-now {
    width: 530px;
    padding: 2em 3em;
    font-size: 1.3em;
    margin: 1em auto; } }

@media screen and (min-width: 1024px) {
  .about-container {
    width: 800px;
    margin: auto; }
    .about-container img {
      width: 50%; }
    .about-container .content {
      width: 50%; } }

.features-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center; }

.features-card {
  position: relative;
  width: 220px;
  height: 220px;
  margin: 1em;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border: solid 8px #7e0003;
  border-radius: 50%;
  padding: 1em; }
  .features-card:hover {
    color: #e3000e;
    border: solid 8px #e3000e; }
  .features-card::before, .features-card::after {
    content: "";
    top: -17px;
    left: -18px;
    position: absolute;
    width: 115%;
    height: 115%; }
  .features-card::before {
    border: 2px solid transparent; }
  .features-card:hover::before {
    border-top-color: #e3000e;
    border-right-color: #e3000e;
    border-bottom-color: #e3000e;
    -webkit-transition: border-top-color 0.15s linear, border-right-color 0.15s linear 0.1s, border-bottom-color 0.15s linear 0.2s;
    transition: border-top-color 0.15s linear, border-right-color 0.15s linear 0.1s, border-bottom-color 0.15s linear 0.2s; }
  .features-card::after {
    border: 0 solid transparent; }
  .features-card:hover::after {
    border-top: 2px solid #e3000e;
    border-left-width: 2px;
    border-right-width: 2px;
    -webkit-transform: rotate(270deg);
            transform: rotate(270deg);
    top: -16px;
    -webkit-transition: border-left-width 0s linear 0.35s, -webkit-transform 0.4s linear 0s;
    transition: border-left-width 0s linear 0.35s, -webkit-transform 0.4s linear 0s;
    transition: transform 0.4s linear 0s, border-left-width 0s linear 0.35s;
    transition: transform 0.4s linear 0s, border-left-width 0s linear 0.35s, -webkit-transform 0.4s linear 0s; }

.circle {
  border-radius: 50%;
  box-shadow: none; }
  .circle::before, .circle::after {
    border-radius: 50%; }

.features-card i {
  font-size: 3em;
  color: #7e0003; }

.features-card h3 {
  font-size: 1.3em;
  margin: 1em auto 0 auto; }

.reviews-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center; }

.reviews-card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 300px;
  margin: 1em;
  padding: 1em;
  box-shadow: 0 0 5px #cecece;
  color: #000;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease; }
  .reviews-card h2 {
    color: #7e0003;
    margin: 1em auto; }
    .reviews-card h2::after {
      content: "";
      display: block;
      width: 100px;
      height: 3px;
      background: #7e0003;
      margin: 1em auto; }
  .reviews-card .review-date {
    text-align: right;
    margin: 2em 0 1.5em auto;
    font-style: italic; }
  .reviews-card:hover {
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.8); }

.btn-more {
  width: 300px;
  padding: 1em 2em; }
  .btn-more i {
    margin-left: 1em; }

@media screen and (min-width: 640px) and (orientation: landscape) {
  .btn-more {
    padding: 2em 3em;
    width: 460px; } }

@media screen and (min-width: 768px) and (orientation: portrait) {
  .btn-more {
    width: 460px;
    padding: 2em 3em;
    font-size: 1.3em; } }

.contact-container {
  display: flex;
  flex-direction: column-reverse;
  flex-wrap: wrap;
  padding: 1em; }

.contact-form-container {
  width: 100%; }

.contact-details {
  width: 100%;
  text-align: left;
  margin-bottom: 3em; }
  .contact-details .contact-line-break {
    margin: 2em auto; }
  .contact-details h3 {
    margin-left: 1.7em; }
  .contact-details i {
    color: #7e0003; }

.contact-phone,
.contact-email {
  margin-right: 1em; }

.coverage {
  width: 100%;
  max-width: 400px;
  margin: auto; }

.coverage-list {
  text-align: left;
  margin-left: 56%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  list-style-type: none;
  margin-bottom: 3em;
  width: 150px; }
  .coverage-list li::before {
    content: "";
    width: 10px;
    height: 10px;
    background-color: #e3000e;
    margin-left: -2em;
    margin-right: 1em;
    display: inline-block;
    vertical-align: middle; }

@media screen and (min-width: 640px) and (orientation: landscape) {
  .contact-container {
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start; }
  .contact-form-container {
    width: 50%;
    margin: 0 0.5em; }
  .contact-details {
    width: 40%;
    margin: 0 0.5em; } }

@media screen and (min-width: 768px) {
  .contact-container {
    flex-direction: row;
    flex-wrap: nowrap;
    padding: 2em; }
  .contact-details {
    padding: 0 1em; }
  .coverage {
    position: absolute;
    margin-top: -27em;
    background: #fff; } }

.contact-form {
  display: flex;
  flex-direction: column; }
  .contact-form label {
    text-align: left;
    margin: 1em auto 1em 0; }
  .contact-form input, .contact-form textarea {
    border-top: none;
    border-right: none;
    border-left: none;
    border-bottom: solid 2px rgba(0, 0, 0, 0.5);
    padding: 1em; }

::-webkit-input-placeholder {
  font-style: italic; }

::-moz-placeholder {
  font-style: italic; }

:-ms-input-placeholder {
  font-style: italic; }

::-ms-input-placeholder {
  font-style: italic; }

::placeholder {
  font-style: italic; }

.row {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: left;
  align-items: baseline; }

.consent-checkbox {
  margin-right: 1em; }

.btn-submit {
  width: 293px;
  padding: 1em 2em;
  margin: 2em auto; }
  .btn-submit .fa-paper-plane {
    margin-left: 1em; }

#map {
  width: 100vw;
  height: 100vh; }

@media screen and (min-width: 1280px) {
  #map {
    height: 60vh; } }

.footer {
  width: 100%;
  background-color: #7e0003;
  color: #fff;
  padding: 1em 1em 2em 1em;
  display: flex;
  flex-direction: column;
  justify-content: center; }
  .footer a {
    color: #fff;
    text-decoration: none;
    font-weight: bold; }

@media screen and (min-width: 640px) {
  .footer {
    flex-direction: row;
    justify-content: space-between; } }

