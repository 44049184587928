@import 'Variables';

.services-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-evenly;
  margin: -3em auto 0 auto;
  align-items: baseline;
}
.service-card {
  width: 300px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  transition: $transition;
  margin: 1em;
  i {
    color: $darkRed;
    font-size: 3em;
    margin: 1em auto 0 auto;
  }
  h2 {
    margin: 1em auto;
    &::before {
      content: "";
      display: block;
      width: 100px;
      height: 3px;
      background-color: $darkRed;
      margin: auto auto 1em auto;
    }
  }
}
.service-list {
  list-style-type: none;
  text-align: left;
  display: block;
  margin: auto;
  width: 220px;
}
@media screen and (min-width: 640px) and (orientation: landscape) {
  .service-card {
    margin: 0;
  }
}
@media screen and (min-width: 1024px) {
  .services-container {
    width: 1024px;
    margin: auto;
  }
}