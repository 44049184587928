@import 'Variables';

.footer {
  width: 100%;
  background-color: $darkRed;
  color: #fff;
  padding: 1em 1em 2em 1em;
  display: flex;
  flex-direction: column;
  justify-content: center;
  a {
    color: #fff;
    text-decoration: none;
    font-weight: bold;
  }
}
@media screen and (min-width: 640px) {
  .footer {
    flex-direction: row;
    justify-content: space-between;
  }
}