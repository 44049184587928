@import 'Variables';

.reviews-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}
.reviews-card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 300px;
  margin: 1em;
  padding: 1em;
  box-shadow: $boxShadowLight;
  color: #000;
  transition: $transition;
  h2 {
    color: $darkRed;
    margin: 1em auto;
    &::after {
      content: "";
      display: block;
      width: 100px;
      height: 3px;
      background: $darkRed;
      margin: 1em auto;
    }
  }
  .review-date {
    text-align: right;
    margin: 2em 0 1.5em auto;
    font-style: italic;
  }
  &:hover {
    box-shadow: $boxShadowHover;
  }
}
.btn-more {
  width: 300px;
  padding: 1em 2em;
  i {
    margin-left: 1em;
  }
}
@media screen and (min-width: 640px) and (orientation: landscape) {
  .btn-more {
    padding: 2em 3em;
    width: 460px;
  }
}
@media screen and (min-width: 768px) and (orientation: portrait) {
  .btn-more {
    width: 460px;
    padding: 2em 3em;
    font-size: 1.3em;
  }
}