@import 'Variables';

.features-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}
.features-card {
  position: relative;
  width: 220px;
  height: 220px;
  margin: 1em;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border: solid 8px $darkRed;
  border-radius: 50%;
  padding: 1em;
  &:hover {
    color: $red;
    border: solid 8px $red;
  }
  &::before,
  &::after {
    content: "";
    top: -17px;
    left: -18px;
    position: absolute;
    width: 115%;
    height: 115%;
  }
  &::before {
    border: 2px solid transparent; // We're animating border-color again
  }
  &:hover::before {
    border-top-color: $red; // Show borders
    border-right-color: $red;
    border-bottom-color: $red;
    transition:
      border-top-color 0.15s linear, // Stagger border appearances
      border-right-color 0.15s linear 0.10s,
      border-bottom-color 0.15s linear 0.20s;
  }
  &::after {
    border: 0 solid transparent; // Makes border thinner at the edges? I forgot what I was doing
  }
  &:hover::after {
    border-top: 2px solid $red; // Shows border
    border-left-width: 2px; // Solid edges, invisible borders
    border-right-width: 2px; // Solid edges, invisible borders
    transform: rotate(270deg); // Rotate around circle
    top: -16px;
    transition:
      transform 0.4s linear 0s,
      border-left-width 0s linear 0.35s; // Solid edge post-rotation
  }
}
.circle {
  border-radius: 50%;
  box-shadow: none;
  &::before,
  &::after {
    border-radius: 50%;
  }
}
.features-card {
  i {
    font-size: 3em;
    color: $darkRed;
  }
  h3 {
    font-size: 1.3em;
    margin: 1em auto 0 auto;
  }
}