@import 'Variables';

#map {
  width: 100vw;
  height: 100vh;
}
@media screen and (min-width: 1280px) {
  #map {
    height: 60vh;
  }
}