@import 'Variables';

.contact-form {
  display: flex;
  flex-direction: column;
  label {
    text-align: left;
    margin: 1em auto 1em 0;
  }
  input, textarea {
    border-top: none;
    border-right: none;
    border-left: none;
    border-bottom: solid 2px rgba(0, 0, 0, 0.5);
    padding: 1em;
  }
}
::placeholder {
  font-style: italic;
}
.row {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: left;
  align-items: baseline;
}
.consent-checkbox {
  margin-right: 1em;
}
.btn-submit {
  width: 293px;
  padding: 1em 2em;
  margin: 2em auto;
  .fa-paper-plane {
    margin-left: 1em;
  }
}