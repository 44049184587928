.about-container {
  display: flex;
  flex-wrap: wrap;
  img {
    width: 100%;
    height: auto;
    margin-top: -2em;
    object-fit: contain;
  }
}
.call-now {
  width: 250px;
  margin: 1em auto;
  padding: 1em 2em;
  i {
    margin-left: 1em;
  }
}

/* RESPONSIVE DESIGN FOR LANDSCAPE MOBILES */ 

@media screen and (min-width: 480px) and (orientation: landscape) {
  .about-container {
    img {
      width: 30%;
    }
    .content {
      width: 70%;
    }
  }
  .call-now {
    width: 325px;
    margin-top: 3em;
  }
}
@media screen and (min-width: 360px) and (orientation: portrait) {
  .call-now {
    width: 315px;
  }
}
@media screen and (min-width: 640px) and (orientation: landscape) {
  .content {
    p:first-of-type {
      margin: 0;
    }
  }
  .call-now {
    margin-top: 2em;
    width: 530px;
    padding: 2em 3em;
  }
}
@media screen and (min-width: 768px) {
  .about-container {
    flex-wrap: nowrap;
  }
  .content {
    padding: 1em;
  }
  .call-now {
    width: 530px;
    padding: 2em 3em;
    font-size: 1.3em;
    margin: 1em auto;
  }
}
@media screen and (min-width: 1024px) {
  .about-container {
    width: 800px;
    margin: auto;
    img {
      width: 50%;
    }
    .content {
      width: 50%;
    }
  }
}