.features-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center; }

.features-card {
  position: relative;
  width: 220px;
  height: 220px;
  margin: 1em;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border: solid 8px #7e0003;
  border-radius: 50%;
  padding: 1em; }
  .features-card:hover {
    color: #e3000e;
    border: solid 8px #e3000e; }
  .features-card::before, .features-card::after {
    content: "";
    top: -17px;
    left: -18px;
    position: absolute;
    width: 115%;
    height: 115%; }
  .features-card::before {
    border: 2px solid transparent; }
  .features-card:hover::before {
    border-top-color: #e3000e;
    border-right-color: #e3000e;
    border-bottom-color: #e3000e;
    transition: border-top-color 0.15s linear, border-right-color 0.15s linear 0.1s, border-bottom-color 0.15s linear 0.2s; }
  .features-card::after {
    border: 0 solid transparent; }
  .features-card:hover::after {
    border-top: 2px solid #e3000e;
    border-left-width: 2px;
    border-right-width: 2px;
    transform: rotate(270deg);
    top: -16px;
    transition: transform 0.4s linear 0s, border-left-width 0s linear 0.35s; }

.circle {
  border-radius: 50%;
  box-shadow: none; }
  .circle::before, .circle::after {
    border-radius: 50%; }

.features-card i {
  font-size: 3em;
  color: #7e0003; }

.features-card h3 {
  font-size: 1.3em;
  margin: 1em auto 0 auto; }
