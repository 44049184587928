@import 'Variables';

.contact-container {
  display: flex;
  flex-direction: column-reverse;
  flex-wrap: wrap;
  padding: 1em;
}
.contact-form-container {
  width: 100%;
}
.contact-details {
  width: 100%;
  text-align: left;
  margin-bottom: 3em;
  .contact-line-break {
    margin: 2em auto;
  }
  h3 {
    margin-left: 1.7em;
  }
  i {
    color: $darkRed;
  }
}
.contact-phone,
.contact-email {
  margin-right: 1em;
}
.coverage {
  width: 100%;
  max-width: 400px;
  margin: auto;
}
.coverage-list {
  text-align: left;
  margin-left: 56%;
  transform: translateX(-50%);
  list-style-type: none;
  margin-bottom: 3em;
  width: 150px;
  li {
    &::before {
      content: "";
      width: 10px;
      height: 10px;
      background-color: $red;
      margin-left: -2em;
      margin-right: 1em;
      display: inline-block;
      vertical-align: middle;
    }
  }
}

@media screen and (min-width: 640px) and (orientation: landscape) {
  .contact-container {
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
  }
  .contact-form-container {
    width: 50%;
    margin: 0 0.5em;
  }
  .contact-details {
    width: 40%;
    margin: 0 0.5em;
  }
}
@media screen and (min-width: 768px) {
  .contact-container {
      flex-direction: row;
      flex-wrap: nowrap;
      padding: 2em;
  }
  .contact-details {
    padding: 0 1em;
  }
  .coverage {
    position: absolute;
    margin-top: -27em;
    background: #fff;
  }
}